import { getUUID } from "@/util";
import axios from "axios";
import { ElMessage } from "element-plus";
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 所有请求的公共地址部分
  timeout: 30000, // 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
// console.log(process.env.VUE_APP_BASE_URL);

http.interceptors.request.use(
  (config) => {
    // config 请求的所有信息
    // console.log(config);

    if (config.method.toUpperCase() === "POST" && !config.noloading) {
      window.document.dispatchEvent(new CustomEvent("loading"));
    }
    config.headers["trace-no"] = getUUID();
    config.params = {
      _t: Date.now() / 1000,
      ...config.params,
    };

    return config; // 将配置完成的config对象返回出去 如果不返回 请求讲不会进行
  },
  (err) => {
    // 请求发生错误时的相关处理 抛出错误
    Promise.reject(err);
  }
);

http.interceptors.response.use(
  (res) => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
    // res 是所有相应的信息
    // console.log(res);
    if (res.config.method.toUpperCase() === "POST") {
      window.document.dispatchEvent(new CustomEvent("loading-close"));
    }

    if (res.data.ok) {
      res = res.data.result;
      return Promise.resolve(res);
    } else if (res.data && !res.data.ok) {
      switch (res.data.code) {
        case 401:
          ElMessage.error("请先登录");
          window.location.href = `${
            process.env.VUE_APP_CLIENT_URL
          }/#/login?redirect=${decodeURIComponent(window.location.href)}`;
          break;
        case 403:
          if (res.data.error === "Forbidden") {
            //todo nest 无token 返回Forbidden
            ElMessage.error("请先登录");
            window.location.href = `${
              process.env.VUE_APP_CLIENT_URL
            }/#/login?redirect=${decodeURIComponent(window.location.href)}`;
          } else {
            ElMessage.error(res.data.message);
          }
          break;

        default:
          ElMessage.error(res.data.message);
          break;
      }
      return Promise.reject(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },
  (err) => {
    // 服务器响应发生错误时的处理
    window.document.dispatchEvent(new CustomEvent("loading-close"));
    Promise.reject(err);
  }
);

export default http;
