import { watch, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import HeaderBar from "@/components/HeaderBar.vue";
const tags = [{
  name: "1",
  label: "自动售后",
  img: require("../assets/16.png")
}, {
  name: "2",
  label: "无限续费",
  img: require("../assets/17.png")
}, {
  name: "3",
  label: "现货秒发",
  img: require("../assets/19.png")
}, {
  name: "4",
  label: "翻车赔付",
  img: require("../assets/18.png")
}];
export default {
  components: {
    HeaderBar
  },
  setup() {
    const state = reactive({
      showBack: false
    });
    const route = useRoute();
    watch(route, val => {
      var _val$meta;
      state.showBack = (_val$meta = val.meta) === null || _val$meta === void 0 ? void 0 : _val$meta.title;
    }, {
      immediate: true,
      deep: true
    });
    return {
      ...toRefs(state),
      tags,
      route
    };
  }
};