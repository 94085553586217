import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import elementRegister from "./elementRegister";
import i18n from "./i18n";
import "./styles/index.scss";
import { getInviteCode, setCookie } from "./util";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
require("dayjs/locale/zh-cn");
dayjs.extend(relativeTime);
dayjs.locale("zh-cn");

router.beforeEach((to, from, next) => {
  const { login } = to.meta;
  const { isLogin } = store.state;
  const inviteCode = getInviteCode();
  if (inviteCode) {
    setCookie("INVITE_CODE", inviteCode, 2);
    Mark.setClientInfo({ cid: inviteCode });
  }
  console.log(to.name);
  Mark.trackPage({ trackId: "ticketman-page-" + to.name });

  if (login && !isLogin) {
    next({
      name: "login",
      query: { redirect: decodeURIComponent(window.location.href) },
    });
  } else {
    next();
  }
});

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(elementRegister)
  .mount("#app");
