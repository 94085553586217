import http from "./http";

export default {
  all: {
    getUserInfo: () => http.get("/users/userInfo"),
  },
  home: {
    getProdcutList: () => http.get("/operate/home-product-list"),
    getCardList: (params) => http.get("/operate/home-card-list", { params }),
    getStatistic: () => http.get("/statistic"),
    getSkuList: (params) => http.get("/product/product-sku", { params }),
    createOrder: (params) => http.post("/order/create", params),
    createRenewOrder: (params) => http.post("/order/renew", params),
    createReserveOrder: (params) =>
      http.post("/order/create-order-reserve", params),
  },
  user: {
    bindMobile: (params) => http.post("/auth/bind-mobile", params),
    changeMobile: (params) => http.post("/auth/change-mobile", params),
    changeInfo: (params) => http.post("users/user/update-info", params),
    getBindWechatCode: (params) => http.get("/auth/bind-wechat", { params }),
    activeUser: (params) => http.get("/users/active", { params }),
  },
  login: {
    judgeCheck: (params) => http.post("/auth/check", params),
    getKey: (params) => http.get("/auth/log-api/log.gif", { params }),
    sendCode: (params) => http.post("/auth/send-code", params),
    getCaptchaImg: () => http.get("/auth/captcha"),
    signupByMobile: (params) => http.post("/auth/signup", params),

    getWechatCode: (params) => http.get("/auth/wechat-code", { params }),

    wechatAuthStatus: (params) =>
      http.post("/auth/wechat-auth-status", params, { noloading: true }),

    getToken: (params) => http.post("/auth/get-token", params),
    setToken: (params) => http.post("/auth/set-token", params),
    logout: (params) => http.post("/auth/logout", params),
  },
  order: {
    createExchangeOrder: (params) => http.post("/order/exchange", params),
    getUserOrders: () => http.get("/ticket/user-order-list"),
    getUserTickets: () => http.get("/ticket/user-ticket-list"),
    getTicketPass: (params) => http.post("/ticket/get-password", params),
    resetTicketPass: (params) => http.post("/ticket/reset-password", params),
    resetTicket: (params) => http.post("/ticket/reset", params),
    resetPin: (params) => http.post("/ticket/reset-pin", params),
    getOrderInfo: (params) => http.get("/order/info", { params }),
    createAlipay: (params) => http.post("/order/alipay", params),
    getPayStatus: (params) => http.get("/order/pay-status", { params }),

    getHistoryOrder: (params) => http.get("/order/history-order", { params }),
    cancelHistoryOrder: (params) =>
      http.post("/order/cancel-order", { params }),

    getTicketToken: (params) => http.post("/ticket/get-token", params),
    getTicketTokenUrl: (params) => http.post("/ticket/get-token-url", params),
  },

  work: {
    creatChat: (params) => http.post("/chat", params),
    getChatInfo: (params) => http.get("/chat", { params }),
    sendMessage: (params) => http.post("/chat/message", params),
    getChatList: (params) => http.get("/chat/my-list", { params }),
    updateChat: (params) => http.post("/chat/update", params),
  },
  invite: {
    getMyInviteInfo: (params) => http.get("/invite/my-invite-info", { params }),
    getUserRebateRecord: (params) =>
      http.get("/invite/user/rebate-record/list", { params }),
    getUserRebateRecordPie: (params) =>
      http.get("/invite/user/rebate-record-pie", { params }),
    getUserRebateRecordLine: (params) =>
      http.get("/invite/user/rebate-record-line", { params }),

    getUserRebate: (params) => http.get("/invite/my-rebate", { params }),
    rebateTake: (params) => http.post("/invite/rebate-take", params),
    getRebateTakes: (params) =>
      http.get("/invite/rebate-take/list", { params }),
  },
  coupon: {
    getOrderCoupontList: (params) =>
      http.post("/coupon/user/order/list", params),

    takeCoupon: (params) => http.post("/coupon/send-plan/take", params),

    getMyCouponList: (params) => http.get("/coupon/user/list", { params }),

    getNoUseCouponList: (params) =>
      http.get("/coupon/no-use/modal-notice", { params }),
    getNewSendCouponList: (params) =>
      http.get("/coupon/send-coupon/modal-notice", { params }),

    getCouponInfoByCode: (params) =>
      http.get("/coupon/get-coupon-by-code", { params }),
  },
};
