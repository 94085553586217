import dayjs from "dayjs";
export default {
  props: {
    couponData: {
      type: Object,
      default: () => ({
        discount: 0,
        disable: false,
        userCoupon: {
          couponId: null,
          userId: null,
          couponInfo: {
            amount: 0,
            type: 1,
            useType: 10,
            status: 0,
            couponId: null
          }
        }
      })
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    validDate() {
      if (this.couponData.userCoupon.couponInfo.validStartTime && new Date(this.couponData.userCoupon.couponInfo.validStartTime).getTime() > Date.now() || this.couponData.userCoupon.isTake == false) {
        return `${dayjs(this.couponData.userCoupon.validStartTime).format("YYYY-MM-DD")}~
          ${dayjs(this.couponData.userCoupon.validEndTime).format("YYYY-MM-DD")}`;
      } else {
        return "还剩" + dayjs(this.couponData.userCoupon.validEndTime).fromNow() + "过期";
      }
    }
  },
  methods: {
    dayjs: dayjs,
    handleClick() {
      if (this.couponData.userCoupon.useStatus !== 1 || this.disable) {
        return;
      }
      this.$emit("select", this.couponData);
    },
    handleUse() {
      this.$emit("use", this.couponData);
    },
    async handleTake() {
      this.$emit("take", this.couponData.userCoupon);
    }
  }
};