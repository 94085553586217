import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b6dc068"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CouponItem = _resolveComponent("CouponItem");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", {
    class: "px-coupon-list-wrapper",
    onClick: _cache[1] || (_cache[1] = (...args) => _ctx.handleClick && _ctx.handleClick(...args))
  }, [_createVNode(_component_el_dialog, {
    "model-value": _ctx.newNoticeVisible,
    title: "恭喜您获得优惠劵",
    width: 380,
    "show-close": false,
    "append-to-body": true,
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "custom-class": "dialog-new-coupon"
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      class: "dialog-ok",
      onClick: _cache[0] || (_cache[0] = (...args) => _ctx.onNewNoticeFinish && _ctx.onNewNoticeFinish(...args))
    }, "前往查看")])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newCouponList, coupon => {
      return _openBlock(), _createBlock(_component_CouponItem, {
        key: coupon.id,
        couponData: {
          userCoupon: coupon
        },
        onTake: _ctx.handleTake,
        showBtn: ""
      }, null, 8, ["couponData", "onTake"]);
    }), 128))]),
    _: 1
  }, 8, ["model-value"])]);
}