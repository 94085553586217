import CryptoJS from "crypto-js/crypto-js";
export const loadScript = (url) => {
  if (/^\/\//.test(url)) {
    url = (location.protocol === "http:" ? "http:" : "https:") + url;
  }
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement("script");
      if (script.readyState) {
        script.onreadystatechange = () => {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null;
            resolve();
          }
        };
      } else {
        script.onload = () => {
          resolve();
        };
      }
      script.src = url;
      document.body.appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");

    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;

      let c_end = document.cookie.indexOf(";", c_start);

      if (c_end == -1) c_end = document.cookie.length;

      return document.cookie.substring(c_start, c_end);
    }
  }

  return "";
};

export function setCookie(name, value, time) {
  var expires = "";
  if (time) {
    var now = new Date();
    var offset = 8;
    var utc = now.getTime() + now.getTimezoneOffset() * 60000;
    var nd = utc + 3600000 * offset;
    var exp = new Date(nd);
    var domain = document.domain.match(/[^.]+\.[^.]+$/)[0];
    exp.setTime(exp.getTime() + time * 60 * 60 * 1000);
    expires = "expires=" + exp.toGMTString() + ";";
  }

  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    ";path=/;" +
    expires +
    "domain=" +
    domain +
    ";";
}

export function getQueryString(name) {
  var reg = new RegExp("(\\?|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.href.replace(/#/g, "&").match(reg);
  if (r != null) return encodeURIComponent(r[2]);
  return null;
}

export function getInviteCode() {
  const r = window.location.href.match(/inviteCode=([a-zA-Z]+)/);
  return r && r[1] ? r[1] : null;
}

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * AES加密
 */
export function Encrypt(word, keyStr) {
  let key, iv, ivStr;
  if (!keyStr) {
    throw new Error("keyStr 不能为空");
  }
  ivStr = keyStr;
  key = CryptoJS.enc.Utf8.parse(keyStr);
  iv = CryptoJS.enc.Utf8.parse(ivStr);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });

  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
