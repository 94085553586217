import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  "element-loading-background": "rgba(0, 0, 0, 0.1)"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  const _component_coupon_notice = _resolveComponent("coupon-notice");
  const _directive_loading = _resolveDirective("loading");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_config_provider, {
    locale: _ctx.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  }, 8, ["locale"]), _createVNode(_component_coupon_notice)])), [[_directive_loading, _ctx.loading, void 0, {
    fullscreen: true,
    lock: true
  }]]);
}