import {
  ElButton,
  ElCol,
  ElContainer,
  ElHeader,
  ElMain,
  ElRow,
  ElMessage,
  ElSelect,
  ElOption,
  ElRadio,
  ElRadioGroup,
  ElInput,
  ElUpload,
  ElDrawer,
  ElDialog,
  ElTable,
  ElTableColumn,
  ElCheckbox,
  ElLoading,
  ElLoadingDirective,
  ElMessageBox,
  ElCarousel,
  ElCarouselItem,
} from "element-plus";
import "element-plus/es/components/message/style/css";
import "element-plus/es/components/message-box/style/css";
const components = [
  ElButton,
  ElCol,
  ElContainer,
  ElHeader,
  ElMain,
  ElRow,
  ElMessage,
  ElSelect,
  ElOption,
  ElRadio,
  ElRadioGroup,
  ElInput,
  ElUpload,
  ElDrawer,
  ElDialog,
  ElTable,
  ElTableColumn,
  ElCheckbox,
  ElCarousel,
  ElCarouselItem,

  ElMessageBox,
  ElLoading,
  ElLoadingDirective,
];

export default function (app) {
  for (const component of components) {
    app.component(component.name, component);
  }
}
