import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/views/home/IndexView.vue";

import SubLayout from "@/layouts/SubLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

const routes = [
  {
    path: "/dev",
    name: "dev",
    component: () => import("@/views/DevC.vue"),
    meta: {
      login: false,
    },
  },
  {
    path: "/guide-pay",
    name: "guidePay",
    component: () => import("@/views/pay/GuidePay.vue"),
    meta: {
      title: "支付宝支付",
    },
  },
  {
    path: "/guide-login",
    name: "guideLogin",
    component: () => import("@/views/login/GuideLogin.vue"),
    meta: {
      title: "微信授权",
      login: false,
    },
  },
  {
    path: "/pay-status",
    name: "payStatus",
    component: () => import("@/views/pay/PayStatus.vue"),
    meta: {
      title: "支付结果",
    },
  },
  {
    name: "main_layout",
    path: "/",
    component: MainLayout,

    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/IndexView.vue"),
        meta: {
          login: false,
        },
      },
      {
        path: "/pay",
        name: "pay",
        component: () => import("@/views/pay/IndexView.vue"),
        meta: {
          title: "订单支付",
          login: true,
        },
      },

      {
        path: "/manage",
        name: "manage",
        component: SubLayout,
        children: [
          {
            name: "work",
            path: "/manage/work",
            component: () => import("@/views/work/IndexView.vue"),
            children: [
              {
                name: "work_new",
                path: "/manage/work/new",
                component: () => import("@/views/work/NewView.vue"),
                meta: {
                  login: true,
                },
              },
              {
                name: "work_detail",
                path: "/manage/work/chat",
                component: () => import("@/views/work/DetailView.vue"),
                meta: {
                  login: true,
                },
              },
            ],
          },
          {
            name: "order",
            path: "/manage/order",
            component: () => import("@/views/order/IndexView.vue"),
            meta: {
              login: true,
            },
          },
          {
            name: "coupon",
            path: "/manage/coupon",
            component: () => import("@/views/coupon/IndexView.vue"),
            meta: {
              title: "我的优惠券",
              login: true,
            },
          },
          {
            name: "exchange",
            path: "/manage/exchange",
            component: () => import("@/views/exchange/IndexView.vue"),
            meta: {
              title: "兑换中心",
              login: true,
            },
          },
          {
            name: "share",
            path: "/manage/share",
            component: () => import("@/views/share/IndexView.vue"),
            meta: {
              title: "我的推广",
              login: true,
            },
          },
          {
            name: "user",
            path: "/manage/user",
            component: () => import("@/views/user/IndexView.vue"),
            meta: {
              title: "编辑资料",
              login: true,
            },
          },
          {
            name: "userBindMobile",
            path: "/manage/user/bind-mobile",
            component: () => import("@/views/user/PhoneBind.vue"),
            meta: {
              title: "绑定手机号",
              login: false,
            },
          },
          {
            name: "withdraw",
            path: "/manage/withdraw",
            component: () => import("@/views/withdraw/IndexView.vue"),
            meta: {
              title: "提现",
              login: true,
            },
          },
        ],
      },
      {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
          login: false,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

// routes.forEach((item) => {
//   router.addRoute(item);
// });

export default router;
