import api from "@/api";
import { MENU_NAV, NOLOGIN_MENU_NAV } from "@/constants";
import { getCookie, setCookie } from "@/util";
import { createStore } from "vuex";

export const mutationTypes = {
  CHANGE_LANG: "CHANGE_LANG",

  SET_USER_INFO: "SET_USER_INFO",

  SET_LOADING: "SET_LOADING",

  SET_IS_FIRST: "SET_IS_FIRST",

  SET_NEW_COUPON_NOTICE: "SET_NEW_COUPON_NOTICE",
};

export default createStore({
  state: {
    lang: "en",
    model: window.outerWidth > 640 ? "PC" : "H5",
    userInfo: {},
    isLogin: getCookie("is_login") === "true",
    loading: false,
    isFirstEveryday: getCookie("is_first_everyday") !== "false",
    newCouponNotice: 1,
  },
  getters: {
    menuNav: (state) => {
      return state.isLogin ? MENU_NAV : NOLOGIN_MENU_NAV;
    },
  },
  mutations: {
    [mutationTypes.CHANGE_LANG](state, lang = "en") {
      state.lang = lang;
    },
    [mutationTypes.SET_USER_INFO](state, userInfo = {}) {
      state.isLogin = !!userInfo.userId;
      state.userInfo = userInfo;
    },
    [mutationTypes.SET_LOADING](state, loading = false) {
      state.loading = loading;
    },
    [mutationTypes.SET_IS_FIRST](state, isFirstEveryday = false) {
      state.isFirstEveryday = isFirstEveryday;
    },
    [mutationTypes.SET_NEW_COUPON_NOTICE](state) {
      state.newCouponNotice++;
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      if (getCookie("is_login") === "true") {
        const user = await api.all.getUserInfo();
        commit(mutationTypes.SET_USER_INFO, user);
      }
    },
    async logout({ commit }) {
      await api.login.logout();
      commit(mutationTypes.SET_USER_INFO, {});
    },
    setLoading({ commit }, payload) {
      commit(mutationTypes.SET_LOADING, payload);
    },
    setIsFirst({ commit }, payload) {
      setCookie("is_first_everyday", payload, 24);
      commit(mutationTypes.SET_IS_FIRST, payload);
    },

    triggerNewCouponNotice({ commit }) {
      commit(mutationTypes.SET_NEW_COUPON_NOTICE);
    },
  },
  modules: {},
});
